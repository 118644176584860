import React, { useState } from 'react';
import "./contact_form.css";
import image from './contact-form.png';
import image2 from './contact_logo.jpg'
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function ContactForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  function sendEmail(e) {
    e.preventDefault();
    // Get the form values
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const message = document.getElementById("message_form").value;

    // Perform validation checks
    if (!name) {
      alert("Παρακαλώ εισάγετε το όνομα σας.");
      return;
    }

    if (!/^\S+@\S+\.\S+$/.test(email)) {
      alert("Παρακαλώ πληκτρολογήστε σωστά το email σας.");
      return;
    }

    if (!/^\d{10}$/.test(phone)) {
      alert("Παρακαλώ εισάγετε το τηλέφωνο σας.\n (Χωρίς +30 ή 0030)");
      return;
    }

    if (message.length > 1000 || message.length < 20) {
      alert("Παρακαλώ εισάγετε ένα κείμενο που να είναι μεγαλύτερο απο 20 χαρακτήρες και λιγότερο από 1000.");
      return;
    }

    emailjs.sendForm('service_tam6oxs',
      'template_vg94593',
      e.target,
      'wyNQnxSauWvLdViMf'
    ).then(res => {
      console.log(res);
      setFormSubmitted(true);
    }).catch(err => console.log(err));
  }


  return (
    <div className='services-container'>

      {formSubmitted ? (
        <div className="contact-container">
          <div className="contact_header">
            <img src={image} width='30rem' height="30rem" alt="contact us" />
            <h2>Φόρμα Επικοινωνίας</h2>
          </div>
          <div className="contact_form">
            <div className="success_message">
              <p>To μηνυμά σας στάλθηκε με επιτυχία! Θα επικοινωνήσουμε το συντομότερο δυνατό!</p>
              <FontAwesomeIcon icon={faCheck} className="success-icon" />
            </div>
          </div>
          <div class="contact_logo">
            <img src={image2} alt="contact us" />
          </div>
        </div>
      ) : (
        <div className="contact-container">
          <div className="contact_header">
            <img src={image} width='30rem' height="30rem" alt="contact icon" />
            <h2>Φόρμα Επικοινωνίας</h2>
          </div>
          <div className="contact_form">
            <form onSubmit={sendEmail}>
              <label htmlFor='name'>Ονοματεπώνυμο</label>
              <input type="text" placeholder='Το ονομά σας...' id="name" name='name' />
              <label htmlFor='email'>Ε-mail</label>
              <input type="text" placeholder='Το email σας...' id="email" name='email' />
              <label htmlFor='phone'>Τηλέφωνο επικοινωνίας</label>
              <input type="text" placeholder='Το τηλέφωνο σας...' id="phone" name='phone' />
              <label htmlFor='message'>Μήνυμα</label>
              <textarea name="message_form" id="message_form" rows="5" cols="76" placeholder="Γράψτε το μήνυμα που θα παραλάβει ο μηχανικός μας..." />
              <input type="submit" value="ΑΠΟΣΤΟΛΗ"></input>
            </form>
          </div>
          <div className="contact_logo">
            <img src={image2} alt="contact us" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactForm