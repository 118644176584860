import "./HeroImgStyles2.css";
import React, { useState, useEffect } from 'react';
import {servicesoverviewdata} from './services-data';


function Heroimg9() {
    return ( 
        <div className="hero2">
            <div className="mask2">
            <img className="into-img2" src={servicesoverviewdata[1]} alt="energy-efficiency" />
            </div>
                    <div className="content2">
                        <h1>Optiplan Engineering Solutions</h1>
                        <p>ΗΛΕΚΤΡΟΛΟΓΙΚΑ ΣΧΕΔΙΑ</p>
                    </div>           
        </div>
     );
}

export default Heroimg9;