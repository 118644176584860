import "./HeroImgStyles.css";
import React, { useState, useEffect } from 'react';
import {sliderData} from './slider-data';


function Heroimg() {
    const[photo,setPhoto]=useState(0);
    const sliderLength=sliderData.length;
    const autoScroll=true;
    let slideInterval;
    let intervalTime=7000;

    const nextSlide=() => {
        setPhoto(photo === sliderLength -1 ? 0 : photo +1);
    };

    function auto() {
        slideInterval=setInterval(nextSlide, intervalTime)
    }

    useEffect(()=> {
        setPhoto(0)
    },[])

    useEffect(()=> {
        if (autoScroll) {
            auto();
        }
        return () => clearInterval(slideInterval);
    },[photo])

    return ( 
        <div className="hero">
            <div className="mask">
                    {sliderData.map((slide, index)=> {
                        return (
                            <div className={index === photo ? "slide-current" : "slide"} key={index}> 
                                { index=== photo && (
                                    <img className="into-img" src={slide.image} alt="energy-efficiency" />
                                )}                        
                            </div>
                        )
                    })}
            </div>
                    <div className="content">
                        <h1>Optiplan Engineering Solutions</h1>
                    </div>           
        </div>
     );
}

export default Heroimg;