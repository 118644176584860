import React from 'react';
import Navbar from "../components/NavBar";
import HeroimgNews from "../components/HeroimgNews";
import Newsoverview from '../components/news_overview';
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function News() {
    const location = useLocation();
    const canonicalUrl = `https://www.op-texniki.gr${location.pathname}`;
    return <div>
        <Helmet>
            <title>News-Optiplan</title>
            <link rel="canonical" href={canonicalUrl} />
            <meta name="description" content="Τεχνικο Γραφείο, Κατάστημα Ανθέων 105 Περαία Ανατολική Θεσσαλονίκη, Υπηρεσίες Μηχανικού, Ενεργειακά Πιστοποιητικά, Ηλεκτρολογικά Σχέδια, Τακτοποιήσεις Αυθαιρέτων, Ανακαινίσεις, Ηλεκτρονική Ταυτότητα Κτηρίου, Μελέτες Φυσικού Αερίου, Πιστοποιητικά Στεγανότητας, Πιστοποιητικά Πυροπροστασίας" />
            <meta name="keywords" content="Υπηρεσίες Μηχανικού, Ενεργειακά Πιστοποιητικά, Ηλεκτρολογικά Σχέδια, Τακτοποιήσεις Αυθαιρέτων, Ανακαινίσεις, Ηλεκτρονική Ταυτότητα Κτηρίου, Μελέτες Φυσικού Αερίου, Πιστοποιητικά Στεγανότητας, Πιστοποιητικά Πυροπροστασίας, Θεσσαλονίκη, Περαία, Τρίλοφος, Πλαγιάρι, Μηχανιώνα, θέρμη, Καλαμαριά" />
        </Helmet>
        <Navbar />
        <HeroimgNews />
        <Newsoverview />
        <Footer />
    </div>
};

export default News;