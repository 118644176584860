import React from 'react';
import Navbar from "../components/NavBar";
import Heroimg7 from "../components/Heroimg7";
import Service4Main from "../components/Service4Main"
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Service4() {
    const location = useLocation();
    const canonicalUrl = `https://www.op-texniki.gr${location.pathname}`;
    return <div>
        <Helmet>
            <title>Ανακαινίσεις - Optiplan</title>
            <link rel="canonical" href={canonicalUrl} />
            <meta name="description" content="Τεχνικο Γραφείο, Κατάστημα Ανθέων 105 Περαία Ανατολική Θεσσαλονίκη, Υπηρεσίες Μηχανικού, Ενεργειακά Πιστοποιητικά, Ηλεκτρολογικά Σχέδια, Τακτοποιήσεις Αυθαιρέτων, Ανακαινίσεις, Ηλεκτρονική Ταυτότητα Κτηρίου, Μελέτες Φυσικού Αερίου, Πιστοποιητικά Στεγανότητας, Πιστοποιητικά Πυροπροστασίας" />
            <meta name="keywords" content="Υπηρεσίες Μηχανικού, Ενεργειακά Πιστοποιητικά, Ηλεκτρολογικά Σχέδια, Τακτοποιήσεις Αυθαιρέτων, Ανακαινίσεις, Ηλεκτρονική Ταυτότητα Κτηρίου, Μελέτες Φυσικού Αερίου, Πιστοποιητικά Στεγανότητας, Πιστοποιητικά Πυροπροστασίας, Θεσσαλονίκη, Περαία, Τρίλοφος, Πλαγιάρι, Μηχανιώνα, θέρμη, Καλαμαριά" />
        </Helmet>
        <Navbar />
        <Heroimg7 />
        <Service4Main />
        <Footer />
    </div>
};

export default Service4;