import "./NavBarStyles.css";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes, FaBars } from 'react-icons/fa';
import Dropdown from "./Dropdown";

function Navbar() {
    const [dropdown, setDropdown] = useState(false);

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click)

    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    window.addEventListener('scroll', changeColor);
    return (
        <div className={color ? "header header-bg" : "header"}>
            <Link to="/">
                <h2>Optiplan</h2>
            </Link>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li>
                    <Link to='/'>Αρχική</Link>
                </li>
                <li onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                    <Link to='/services'>Υπηρεσίες</Link>
                    {dropdown && <Dropdown />}
                </li>
                <li>
                    <Link to='/contact'>Επικοινωνία</Link>
                </li>
                <li>
                    <Link to='/news'>Νέα</Link>
                </li>
            </ul>
            <div className="hamburger" onClick={handleClick}>
                {click ? (<FaTimes size={20} style={{ color: 'black' }} />) : (<FaBars size={20} style={{ color: 'bisque' }} />)}
            </div>
        </div>
    );
}

export default Navbar;