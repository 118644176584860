import "./Dropdown.css";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Dropdown() {
    const [dropdown, setDropdown] = useState(false);
    return (
        <>
            <ul className={dropdown ? "services-submenu clicked" : "services-submenu"} onClick={() => setDropdown(!dropdown)}>
                <li className="submenu-items">
                    <Link to='/services/fusiko-aerio' onClick={() => setDropdown(false)}>
                        <p>Φυσικό Αέριο</p>
                    </Link>
                </li>
                <li className="submenu-items" >
                    <Link to='/services/energeiako-pistopoihtiko' onClick={() => setDropdown(false)}>
                        <p>Πιστοποιητικό Ενεργειακής Απόδοσης</p>
                    </Link>
                </li>
                <li>
                    <Link to='/services/fotovoltaika' className="submenu-items" onClick={() => setDropdown(false)}>
                        <p>Φωτοβολταϊκά Συστήματα</p>
                    </Link>
                </li>
                <li>
                    <Link to='/services/anakainiseis' className="submenu-items" onClick={() => setDropdown(false)}>
                        <p>Ανακαινίσεις και Έκδοση Αδειών Μικρής Κλίμακας</p>
                    </Link>
                </li>
                <li>
                    <Link to='/services/eksoikonomw' className="submenu-items" onClick={() => setDropdown(false)}>
                        <p>Επιδοτούμενα Προγράμματα Εξοικονομώ</p>
                    </Link>
                </li>
                <li>
                    <Link to='/services/hlektrologika-sxedia' className="submenu-items" onClick={() => setDropdown(false)}>
                        <p>Ηλεκτρολογικά Σχέδια</p>
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default Dropdown;