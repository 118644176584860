import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDNklVqLCeylzhsq614uWyjRx3bGeRBVtY",
    authDomain: "op-texniki.firebaseapp.com",
    projectId: "op-texniki",
    storageBucket: "op-texniki.appspot.com",
    messagingSenderId: "371210082685",
    appId: "1:371210082685:web:b5ad73e885b79c12415414",
    measurementId: "G-BJ6L2L72BH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
export default firestore;
