import "./service2_main.css";

function Service2Main() {
    return (
        <div className='service-main-container2'>
            <div className='service-nested-container2'>
                <div className='Description2'>
                    <p>Το γραφείο μας διαθέτει διπλωματούχους μηχανικούς εγγεγραμμένους στο μητρώο ενεργειακών επιθεωρητών του Υπουργείου Περιβάλλοντος και Ενέργειας, οι
                        οποίοι αναλαμβάνουν την έκδοση Πιστοποιητικών Ενεργειακής Απόδοσης (Π.Ε.Α.).
                        Επιπλέον, πέρα από την έκδοση του πιστοποιητικού, ο μηχανικός βρίσκεται στη διαθέσή σας για να σας παραθέσει συμβουλές εξοικονόμησης ενέργειας.</p>
                </div>
                <div className='services2'>
                    <ul>
                        <li><h4>Στάδια Έκδοσης Π.Ε.Α.</h4></li>
                        <li><p>Συλλογή πληροφοριών για το ακίνητο όπως η χρήση, οι διαστάσεις και τα συστήματα θέρμανσης/ψύξης</p></li>
                        <i class="arrow down"></i>
                        <li><p>Εκτίμηση της ενεργειακής απόδοσης του ακινήτου μέσω αναλυτικών μεθόδων και ψηφιακών εργαλείων</p></li>
                        <i class="arrow down"></i>
                        <li><p>Εκτίμηση και καταγραφή πρότασεων ενεργειακών παρεμβάσεων για την αναβάθμιση της ενεργειακής απόδοσης του ακινήτου</p></li>
                        <i class="arrow down"></i>
                        <li><p>Παρακολούθηση και αξιολόγηση των μετατροπών που πραγματοποιήθηκαν στο ακίνητο</p></li>
                        <i class="arrow down"></i>
                        <li><p>Έκδοση Πιστοποιητικού Ενεργειακής Απόδοσης του ακινήτου μέσω πλατφόρμας του Υπουργείου Περιβάλλοντος και Ενέργειας</p></li>
                        <i class="arrow down"></i>
                        <li><p>Εγγραφή της πιστοποίησης στη βάση δεδομένων του Ε.Ο.Ε.Π. και ενημέρωση των υπηρεσιών</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Service2Main