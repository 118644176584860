import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import firestore from '.././firebase.js';
import "./article_detail.css";

const ArticleDetail = () => {
    const { id } = useParams();
    const articleId = id;
    const [article, setArticle] = useState(null);
    useEffect(() => {
        const fetchArticleData = async (documentId) => {
            try {
                const articleRef = doc(firestore, 'articles', documentId);
                const articleSnapshot = await getDoc(articleRef);
                if (articleSnapshot.exists()) {
                    const { id, data } = articleSnapshot.data();
                    const parsedData = JSON.parse(data.replace(/'/g, '"'));
                    setArticle({ id, ...parsedData });
                } else {
                    console.log('Document does not exist');
                }
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };

        fetchArticleData(articleId);
    }, [articleId]);

    if (!article) {
        return <div>Article not found</div>;
    }

    // Function to format the text content
    const formatContent = (text) => {
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Replace URLs with clickable links
        const textWithLinks = text.replace(urlRegex, (url) => {
            return `<a style="color:blue;" href="${url}" target="_blank">${url}</a>`;
        });

        return textWithLinks;
    };
    // Format the article content
    const formattedContent = formatContent(article.text);

    return (
        <div className='main-article-container'>
            <div className='inner-article-container'>
                <div className='article-image'>
                    <img src={article.imageUrl} alt={article.title} />
                </div>
                <div className='article-title'>
                    <h1 >{article.title}</h1>
                </div>
                <div className='article-body' dangerouslySetInnerHTML={{ __html: formattedContent }} />
                <div className='article-footer'>Optiplan Τεχνικό Γραφείο Περαία/Θεσσαλονίκη, Ανθέων 105, 57019, Υπηρεσίες Μηχανικών</div>
            </div>
        </div>
    );
};

export default ArticleDetail;
