import "./service_main.css";

function Service4Main() {
    return (
        <div className='service-main-container'>
            <div className='service-nested-container'>
                <div className='Description'>
                    <p>Η μελετητική - τεχνική εταιρεία Optiplan Engineering Solutions αναλαμβάνει την έκδοση Έγκρισης  Εργασιών Δόμησης Μικρής Κλίμακας (ΕΕΔΜΚ) ή
                        Άδειας Λειτουργίας (π.χ. Καταστημάτων εστίασης). Μπορείτε να επικοινωνήσετε με τους μηχανικούς του γραφείου μας και να ενημερωθείτε
                        σχετικά με την διαδικασία και τις απαιτήσεις έκδοσης της εκάστοτε άδειας.</p>
                </div>
                <div className='services1'>
                    <ul>
                        <li><p>Αποξηλώσεις παλαιών εγκαταστάσεων</p></li>
                        <li><p>Ηλεκτρολογικές εργασίες εγκατάστασης - αντικατάστασης</p></li>
                        <li><p>Ανακαίνιση χώρου</p></li>
                        <li><p>Εγκατάσταση - επισκευή υδραυλικών συστημάτων</p></li>
                        <li><p>Επισκευή - συντήρηση υγρομόνωσης στέγης και δώματος</p></li>
                        <li><p>Ενεργειακή αναβάθμιση ακινήτου (Κουφώματα - Μόνωση- Θέρμανση - Ψύξη)</p></li>
                        <li><p>Εγκατάσταση - επισκευή δαπέδων</p></li>
                        <li><p>Επισκευή - συντήρηση τοιχοποίας (βαφή - σοβάς)</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Service4Main