import React from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToWWW = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (window.location.hostname === 'op-texniki.gr') {
      navigate(`https://www.op-texniki.gr${window.location.pathname}`, { replace: true });
    }
  }, [navigate]);

  return null;
};

export default RedirectToWWW;