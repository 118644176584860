import "./Mainhome.css";
import { Link } from 'react-router-dom';

function Mainhome() {
    return (
        <div className='main-container'>
            <div className='nested-container'>
                <div className='left_nested_container'>
                    <h3>Οι υπηρεσίες μας</h3>
                    <ul>
                        <li>
                            <Link to="/services/fusiko-aerio"><p>ΦΥΣΙΚΟ ΑΕΡΙΟ</p></Link>
                        </li>
                        <li>
                            <Link to="/services/energeiako-pistopoihtiko"><p>ΠΙΣΤΟΠΟΙΗΤΙΚΟ ΕΝΕΡΓΕΙΑΚΗΣ ΑΠΟΔΟΣΗΣ</p></Link>
                        </li>
                        <li>
                            <Link to="/services/fotovoltaika"><p>ΦΩΤΟΒΟΛΤΑΙΚΑ ΣΥΣΤΗΜΑΤΑ</p></Link>
                        </li>
                        <li>
                            <Link to="/services/anakainiseis"><p>ΑΝΑΚΑΙΝΙΣΕΙΣ ΚΑΙ ΑΔΕΙΕΣ ΜΙΚΡΗΣ ΚΛΙΜΑΚΑΣ</p></Link>
                        </li>
                        <li>
                            <Link to="/services/eksoikonomw"><p>ΠΡΟΓΡΑΜΜΑΤΑ ΕΞΟΙΚΟΝΟΜΩ</p></Link>
                        </li>
                        <li>
                            <Link to="/services/hlektrologika-sxedia"><p>ΗΛΕΚΤΡΟΛΟΓΙΚΑ ΣΧΕΔΙΑ</p></Link>
                        </li>
                    </ul>

                </div>
                <div className='right_nested_container'>
                    <h3>Σχετικά με εμάς</h3>
                    <h5>Η ομάδα μας</h5>
                    <p>Η ομάδα της Optiplan Solutions απαρτίζεται από διπλωματούχους μηχανικούς, οι οποιοί καλύπτουν ένα ευρύ φάσμα των ειδικοτήτων της Πολυτεχνικής Σχολής.
                        Βασιζόμενοι στην εκπαίδευση τους, την εμπειρία που έχουν λάβει και την επιθυμία για αναβάθμιση και εκσυχρονισμό, οι μηχανικοί μας είναι σε θέση να
                        σας συμβουλεύσουν για την επίτευξη της ενεργειακής αναβάθμισης του δικού σας χώρου.</p>
                    <h5>Η τεχνογνωσία μας</h5>
                    <p>Η μελετητική ομάδα της Optiplan Solutions, σε συνδυασμό με το δίκτυο συνεργασίας που έχει αναπτύξει με συνεργεία έμπειρων και εξειδικευμένων εγκαταστατών, μπορεί
                        να αναλάβει την ενεργειακή αναβάθμιση τους ακινήτου σας, την εγκατάσταση συστημάτων αυτόνομης θέρμανσης και φωτοβολταϊκών συστημάτων, καθώς και την συγκρότηση
                        φακέλων για επιδοτούμενα προγράμματα Εξοικονομώ.</p>
                    <h5>Η εμπειρία μας</h5>
                    <p>Η εμπειρία που διαθέτει η ομάδα της Optiplan Solutions, της επιτρέπει να αναγνωρίζει εύκολα τα σημεία που μπορούν να οδηγήσουν σε μείωση των ενεργειακών δαπανών
                        ενός ακινήτου, συμπεριλαμβάνοντας πάντα τις ανάγκες και επιθυμίες του ιδιοκτήτη. Κύριος στόχος στα έργα που αλαμβάνουμε είναι πάντα η αριστεία και το ποιοτικό αποτέλεσμα.</p>
                </div>
            </div>
        </div>


    )
}

export default Mainhome