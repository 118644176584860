import './index.css';
import Home from "./routes/Home";
import Services from "./routes/Services";
import Contact from "./routes/Contact";
import Service1 from './routes/Service1';
import Service2 from './routes/Service2';
import Service3 from './routes/Service3';
import Service4 from './routes/Service4';
import Service5 from './routes/Service5';
import Service6 from './routes/Service6';
import News from "./routes/News";
import Article from './routes/Article';
import ScrollToTop from "./scrollToTop";
import Preloader from './components/preloader';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from 'react';
import RedirectToWWW from './RedirectoWWW';

function App() {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time)
    )
  }

  sleep(5).then(() => {
    setImagesLoaded(true);
  })

  return (
    <div>
      {!imagesLoaded && <Preloader />}
      {imagesLoaded &&
        <ScrollToTop>
          <RedirectToWWW />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/fusiko-aerio" element={<Service1 />} />
            <Route path="/services/energeiako-pistopoihtiko" element={<Service2 />} />
            <Route path="/services/fotovoltaika" element={<Service3 />} />
            <Route path="/services/anakainiseis" element={<Service4 />} />
            <Route path="/services/eksoikonomw" element={<Service5 />} />
            <Route path="/services/hlektrologika-sxedia" element={<Service6 />} />
            <Route path="/news" element={<News />} />
            <Route path="/article/:id" element={<Article />} />
          </Routes>
        </ScrollToTop>}
    </div>
  );
}

export default App;
