import "./service3_main.css";

function Service3Main() {
    return (
        <div className='service-main-container'>
            <div className='service-nested-container'>
                <div className='Description'>
                    <p>Η εταιρεία μας αλαναμβάνει την μελέτη και εγκατάσταση φωτοβολταϊκών
                        συστημάτων κατάλληλης δυναμικότητας σύμφωνα με τις ανάγκες σας. Τα συστήματα αυτά
                        μπορούν να προορίζονται είτε για αυτοκατανάλωση, είτε για net metering.
                        Μπορείτε να επικοινωνήσετε με τους μηχανικούς του γραφείου μας και να λάβετε
                        πληροφορίες σχετικά με τις δυνατότητες που είναι εφικτές για τον δικό σας χώρο.</p>
                </div>
                <div className='services1'>
                    <ul>
                        <li><p>Μελέτη και σχεδιασμός φωτοβολταϊκών συστημάτων</p></li>
                        <li><p>Εγκατάσταση φωτοβολταϊκών συστημάτων</p></li>
                        <li><p>Συντήρηση φωτοβολταϊκών συστημάτων</p></li>
                        <li><p>Επισκευή φωτοβολταϊκών συστημάτων</p></li>
                        <li><p>Παρακολούθηση της απόδοσης φωτοβολταϊκών συστημάτων</p></li>
                        <li><p>Παροχή συμβουλών σχετικά με τα φωτοβολταϊκά συστήματα</p></li>
                        <li><p>Εύρεση βέλτιστης τοποθεσίας φωτοβολταικού συστήματος</p></li>
                        <li><p>Σχεδιασμός οικονομικής-ενεργειακής εξοικονόμησης</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Service3Main