import "./service5_main.css";

function Service5Main() {
    return (
        <div className='service-main-container5'>
            <div className='service-nested-container5'>
                <div className='Description5'>
                    <p>Η εταιρία ανακαινίσεων - τεχνικό γραφείο Optiplan Engineering Solutions στελεχωμένη
                        από έμπειρους μηχανικούς, ενεργειακούς επιθεωρητές και συνεργεία μονωτών, ηλεκτρολόγων και
                        θερμοϋδραυλικών είναι σε θέση να διεκπεραιώσει με απόλυτη αξιοπιστία και ευθύνη την
                        ένταξή σας σε επιδοτούμενα προγράμματα εξασφαλίζοντας την ενεργειακή αναβάθμιση του ακινήτου σας.</p>
                </div>
                <div className='services5'>
                    <ul>
                        <li><h4>Τα βήματα</h4></li>
                        <li><p>Παραλαβή και έλεγχος δικαιολογητικών</p></li>
                        <i class="arrow down"></i>
                        <li><p>Αυτοψία χώρου και έκδοση Α' Π.Ε.Α.</p></li>
                        <i class="arrow down"></i>
                        <li><p>Καταχώρηση φακέλου αίτησης στην πλατφόρμα του προγράμματος</p></li>
                        <i class="arrow down"></i>
                        <li><p>Έκδοση Ηλεκτρονικής Ταυτότητας Κτιρίου (Η.Τ.Κ.)</p></li>
                        <i class="arrow down"></i>
                        <li><p>Εκταμίευση προκαταβολής δανείων ή ίδιων κεφαλαίων για την έναρξη εργασιών</p></li>
                        <i class="arrow down"></i>
                        <li><p>Σύναψη ιδιωτικών συμφωνητικών με τα συνεργεία</p></li>
                        <i class="arrow down"></i>
                        <li><p>Επίβλεψη των εργασιών από μηχανικό της Optiplan Engineering Solutions</p></li>
                        <i class="arrow down"></i>
                        <li><p>Δεύτερη αυτοψία χώρου και έκδοση Β' Π.Ε.Α.</p></li>
                        <i class="arrow down"></i>
                        <li><p>Κατάρτιση φακέλου τελικής φάσης και αποστολή για έλεγχο</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Service5Main