import React from 'react';
import Navbar from "../components/NavBar";
import HeroimgContact from "../components/HeroimgContact";
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Contact() {
    const location = useLocation();
    const canonicalUrl = `https://www.op-texniki.gr${location.pathname}`;
    return <div>
        <Helmet>
            <title>Contact - Optiplan</title>
            <link rel="canonical" href={canonicalUrl} />
            <meta name="description" content="Τεχνικο Γραφείο, Κατάστημα Ανθέων 105 Περαία Ανατολική Θεσσαλονίκη, Υπηρεσίες Μηχανικού, Ενεργειακά Πιστοποιητικά, Ηλεκτρολογικά Σχέδια, Τακτοποιήσεις Αυθαιρέτων, Ανακαινίσεις, Ηλεκτρονική Ταυτότητα Κτηρίου, Μελέτες Φυσικού Αερίου, Πιστοποιητικά Στεγανότητας, Πιστοποιητικά Πυροπροστασίας" />
            <meta name="keywords" content="Υπηρεσίες Μηχανικού, Ενεργειακά Πιστοποιητικά, Ηλεκτρολογικά Σχέδια, Τακτοποιήσεις Αυθαιρέτων, Ανακαινίσεις, Ηλεκτρονική Ταυτότητα Κτηρίου, Μελέτες Φυσικού Αερίου, Πιστοποιητικά Στεγανότητας, Πιστοποιητικά Πυροπροστασίας, Θεσσαλονίκη, Περαία, Τρίλοφος, Πλαγιάρι, Μηχανιώνα, θέρμη, Καλαμαριά" />
        </Helmet>
        <Navbar />
        <HeroimgContact />
        <ContactForm />
        <Footer />
    </div>;
}

export default Contact;