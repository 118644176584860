import "./ServicesOverview.css";
import { Link } from 'react-router-dom';
import { servicesoverviewdata } from './services-data';

function ServicesOverview() {

    return (
        <div className='services-container'>
            <div className='services-nested-container'>
                <Link to="/services/fusiko-aerio">
                    <div className="grid-item">
                        <img className='image' src={servicesoverviewdata[0]} alt="Energy Saving, Natural Gas, Φυσικό αέριο, Εξοικονόμηση Ενέργειας" />
                        <div className='content'>
                            <h2>ΦΥΣΙΚΟ ΑΕΡΙΟ</h2>
                            <p>Η ομάδα μηχανικών και εγκαταστατών του γραφείου μας αναλαμβάνει την υλοποίηση εσωτερικών
                                εγκαταστάσεων φυσικού αερίου...
                            </p>
                        </div>
                    </div>
                </Link>
                <Link to="/services/energeiako-pistopoihtiko">
                    <div className="grid-item">
                        <img className='image' src={servicesoverviewdata[2]} alt="Energy Saving, Certificate, PEA, Πιστοποιητικό Ενεργειακής Απόδοσης ΠΕΑ, Εξοικονόμηση Ενέργειας" />
                        <div className='content'>
                            <h2>ΠΙΣΤΟΠΟΙΗΤΙΚΟ ΕΝΕΡΓΕΙΑΚΗΣ ΑΠΟΔΟΣΗΣ</h2>
                            <p>Το γραφείο μας διαθέτει διπλωματούχους μηχανικούς εγγεγραμμένους στο μητρώο ενεργειακών
                                επιθεωρητών του Υπουργείου...
                            </p>
                        </div>
                    </div>
                </Link>
                <Link to="/services/fotovoltaika">
                    <div className="grid-item">
                        <img className='image' src={servicesoverviewdata[4]} alt="Energy Saving, Solar Panels, Φωτοβολταικά, Εξοικονόμηση Ενέργειας" />
                        <div className='content'>
                            <h2>ΦΩΤΟΒΟΛΤΑΙΚΑ ΣΥΣΤΗΜΑΤΑ</h2>
                            <p>Η εταιρεία μας αλαναμβάνει την μελέτη και εγκατάσταση φωτοβολταϊκών συστημάτων
                                κατάλληλης δυναμικότητας...
                            </p>
                        </div>
                    </div>
                </Link>
                <Link to="/services/anakainiseis">
                    <div className="grid-item">
                        <img className='image' src={servicesoverviewdata[3]} alt="Energy Saving, Reconstruction, Ανακαίνισεις, Εξοικονόμηση Ενέργειας" />
                        <div className='content'>
                            <h2>ΑΝΑΚΑΙΝΙΣΕΙΣ ΚΑΙ ΑΔΕΙΕΣ ΜΙΚΡΗΣ ΚΛΙΜΑΚΑΣ</h2>
                            <p>Το γραφείο μας αναλαμβάνει την έκδοση Έγκρισης  Εργασιών Δόμησης Μικρής Κλίμακας (ΕΕΔΜΚ) ή
                                Άδειας Λειτουργίας (π.χ. Καταστημάτων εστίασης)...
                            </p>
                        </div>
                    </div>
                </Link>
                <Link to="/services/eksoikonomw">
                    <div className="grid-item">
                        <img className='image' src={servicesoverviewdata[5]} alt="Energy Saving, Saving Energy Program , Προγραμμα Εξοικονομω, Εξοικονόμηση Ενέργειας" />
                        <div className='content'>
                            <h2>ΠΡΟΓΡΑΜΜΑΤΑ ΕΞΟΙΚΟΝΟΜΩ</h2>
                            <p>Το γραφείο μας αναλαμβάνει την ενεργειακή αναβάθμιση του χώρου σας με στόχο την εξοικονόμηση
                                ενέργειας που καταναλώνετε για θέρμανση...
                            </p>
                        </div>
                    </div>
                </Link>
                <Link to="/services/hlektrologika-sxedia">
                    <div className="grid-item">
                        <img className='image' src={servicesoverviewdata[1]} alt="Energy Saving, Electricity Planning, Ηλεκτρολογικο Σχεδιο, Εξοικονόμηση Ενέργειας" />
                        <div className='content'>
                            <h2>ΗΛΕΚΤΡΟΛΟΓΙΚΑ ΣΧΕΔΙΑ</h2>
                            <p>Η ομάδα μας, με πολύχρονη εμπειρία στην μελέτη και κατασκευή  ηλεκτρικών έργων αναλαμβάνει
                                την έκδοση της Υπεύθυνης ...
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default ServicesOverview