import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firestore from '.././firebase.js';
import { getDocs, collection } from 'firebase/firestore';
import './news_overview.css';

function Newsoverview() {
    const [currentPage, setCurrentPage] = useState(0);
    const [articles, setArticles] = useState([]);
    const articlesPerPage = 4;

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const articlesRef = collection(firestore, 'articles');
                const snapshot = await getDocs(articlesRef);
                const fetchedArticles = snapshot.docs.map(doc => {
                    const { id, data } = doc.data();
                    const articleId = data.id;
                    const parsedData = JSON.parse(data.replace(/'/g, '"')); // Replace single quotes with double quotes
                    return { id: articleId, ...parsedData };
                });
                setArticles(fetchedArticles);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    const indexOfLastArticle = (currentPage + 1) * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
    const totalPages = Math.ceil(articles.length / articlesPerPage);
    return (
        <div className='main-news-container'>
            <div className='main-news-grid-container'>
                {currentArticles.map((article, index) => (
                    <Link to={`/article/${article.id}`} key={index} className="news-grid-item-link">
                        <div className="news-grid-item" key={index}>
                            <div className="news-item-image">
                                <img src={article.imageUrl} alt={`Article ${article.id}`} />
                            </div>
                            <div className="news-item-title">
                                {article.title}
                            </div>
                            <div className="news-item-description">
                                {article.description}..
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            {/* Pagination Controls */}
            {totalPages > 1 && (
                <div className="pagination">
                    {/* Previous Button */}
                    {currentPage > 0 && (
                        <button
                            className="page_button"
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            &lt;
                        </button>
                    )}

                    {/* Next Button */}
                    {currentPage < totalPages - 1 && (
                        <button
                            className="page_button"
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            &gt;
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default Newsoverview;