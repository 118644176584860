import "./HeroImgStyles2.css";
import React, { useState, useEffect } from 'react';
import { sliderData } from './slider-data';


function HeroimgNews() {
    const [photo, setPhoto] = useState(0);
    const sliderLength = sliderData.length;
    const autoScroll = true;
    let slideInterval;
    let intervalTime = 7000;

    const nextSlide = () => {
        setPhoto(photo === sliderLength - 1 ? 0 : photo + 1);
    };

    function auto() {
        slideInterval = setInterval(nextSlide, intervalTime)
    }

    useEffect(() => {
        setPhoto(0)
    }, [])

    useEffect(() => {
        if (autoScroll) {
            auto();
        }
        return () => clearInterval(slideInterval);
    }, [photo])

    return (
        <div className="hero2">
            <div className="mask2">
                {sliderData.map((slide, index) => {
                    return (
                        <div className={index === photo ? "slide-current2" : "slide2"} key={index}>
                            {index === photo && (
                                <img className="into-img2" src={slide.image} alt="energy-efficiency" />
                            )}
                        </div>
                    )
                })}
            </div>
            <div className="content2">
                <h1>Optiplan Engineering Solutions</h1>
                <p>ΝΕΑ</p>
            </div>
        </div>
    );
}

export default HeroimgNews;