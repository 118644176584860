export const sliderData=[
    {
        image:'/assets/solar_panels.jpeg'
    },
    {
        image:'/assets/green_light.jpg'
    },
    {
        image:'/assets/planning.jpg'
    }
];