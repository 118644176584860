import React from "react";
import { FaFacebook, FaHome, FaMailBulk } from "react-icons/fa";
import { PiPhoneCallFill } from "react-icons/pi";
import './FooterStyles.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <h3>
            <u>Στοιχεία Επικοινωνίας</u>
          </h3>
          <div className="location">
            <FaHome size={25} style={{ marginRight: "1rem" }} />
            <h4>

              Ανθέων 105, Περαία - Νέοι Επιβάτες, Θεσσαλονίκη 57019
            </h4>
          </div>
          <div className="Phone">
            <PiPhoneCallFill size={25} style={{ marginRight: "1rem" }} />
            <h4>
              23921 111 24
            </h4>
          </div>
          <div className="email">
            <FaMailBulk size={25} style={{ marginRight: "1rem" }} />
            <h4>
              info@optiplangroup.com
            </h4>
          </div>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1215.5956189200513!2d22.91011274197513!3d40.50264808175882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a815dc26a7958d%3A0x2becdb2ecb917770!2sAntheon%20105%2C%20Nei%20Epivates%20570%2019!5e0!3m2!1sen!2sgr!4v1687619011200!5m2!1sen!2sgr"
              width="320"
              height="220"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="right">
          <h3>
            <u>Η Εταιρεία</u>
          </h3>
          <h4>
            Το τεχνικό γραφείο Optiplan με έδρα την Περαία Θεσσαλονίκης στην οδό
            Ανθέων 105, αποτελεί έναν από τους πυλώνες της εταιρίας Optiplan
            Solutions. Ιδρύθηκε το 2021 με στόχο την παροχή ποιοτικών υπηρεσιών
            ενεργειακής αναβάθμισης και την άρτια εξυπηρέτηση των πελατών της.
          </h4>
          <h3>
            <u>Social Media</u>
          </h3>
          <div className="social">
            <FaFacebook size={55} style={{ marginRight: "0.5rem" }} />
          </div>
        </div>
      </div>
      <div className="rights-reserved">
        <p>© Copyright 2021 Optiplan. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
