import "./service2_main.css";

function Service6Main() {
    return (
        <div className='service-main-container5'>
            <div className='service-nested-container5'>
                <div className='Description5'>
                    <p>Η μελετητική - τεχνική εταιρία Optiplan Engineering Solutions με πολύχρονη
                        εμπειρία στην μελέτη και κατασκευή  ηλεκτρολογικών εγκαταστάσεων αναλαμβάνει την έκδοση της
                        Υπεύθυνης Δήλωσης Εγκαταστάτη (Υ.Δ.Ε.) –Πιστοποιητικού ΔΕΗ & των ηλεκτρολογικών σχεδίων για όλους τους
                        τύπους των εγκαταστάσεων με αμεσότητα και υπευθυνότητα σε ανταγωνιστική τιμή.
                    </p>
                </div>
                <div className='services5'>
                    <ul>
                        <li><h4>Τα βήματα</h4></li>
                        <li><p>Γρήγορη επίσκεψη στον χώρο σας για αυτοψία της ηλεκτρολογικής εγκατάστασης</p></li>
                        <i class="arrow down"></i>
                        <li><p>Καθορισμός της θέσης του γενικού ηλ. πίνακα</p></li>
                        <i class="arrow down"></i>
                        <li><p>Απεικόνιση του γενικού ηλ. πίνακα των φωτιστικών σωμάτων και των ηλεκτρικών συσκευών σε ηλεκτρολογικό σχέδιο</p></li>
                        <i class="arrow down"></i>
                        <li><p>Κατανομή των φωτιστικών σωμάτων και των ρευματοδοτών σε γραμμές τροφοδότησης στο σχέδιο εσωτερικής ηλ. εγκατάστσης</p></li>
                        <i class="arrow down"></i>
                        <li><p>Υπολογισμός - απαρίθμηση της εγκατεστημένης ισχύος</p></li>
                        <i class="arrow down"></i>
                        <li><p>Έλεγχος για αστοχίες-βλάβες</p></li>
                        <i class="arrow down"></i>
                        <li><p>Συμβουλές για διόρθωση των αστοχιών-βλαβών</p></li>
                        <i class="arrow down"></i>
                        <li><p>Υποβολή των απαιτούμενων σχεδίων και της ΥΔΕ στον ΔΕΔΔΗΕ</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Service6Main