import React from 'react';
import "./preloader.css";

function Preloader() {
  return (
    <div className="Preloader">
      <div className="Preloader__spinner"></div>
    </div>
  );
}

export default Preloader;