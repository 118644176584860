import "./service_main.css";

function Service1Main() {
    return (
        <div className='service-main-container1'>
            <div className='service-nested-container1'>
                <div className='Description1'>
                    <p>Η ομάδα μηχανικών και εγκαταστατών του γραφείου μας αναλαμβάνει την υλοποίηση εσωτερικών εγκαταστάσεων φυσικού αερίου οικιακής και επαγγελματικής χρήσης, καθώς και την σύνταξη πλήρους φακέλου για την ΕΔΑ Θες/νικης.
                        Επιπλέον, οι υπηρεσίες των εξειδικευμένων τεχνικών φυσικού αερίου αναφέρονται στην εγκατάσταση, συντήρηση και επισκευή των συστημάτων φυσικού αερίου.
                        Οι τεχνικοί φυσικού αερίου είναι απαραίτητοι για να εξασφαλίσουν την ασφαλή και αξιόπιστη παροχή του φυσικού αερίου στις οικογενειακές και επαγγελματικές χρήσεις.</p>
                </div>
                <div className='services1'>
                    <ul>
                        <li><p>Εγκατάσταση Συστημάτων Φυσικού Αερίου</p></li>
                        <li><p>Συντήρηση Συστημάτων Φυσικού Αερίου</p></li>
                        <li><p>Ελέγχος Ασφάλειας των Συστημάτων Φυσικού Αερίου</p></li>
                        <li><p>Αίτηση Σύνδεση Φυσικού Αερίου στην Κεντρική Παροχή</p></li>
                        <li><p>Έλεγχος και Έκδοση Πιστοποιητικού Στεγανότητας Δικτύου</p></li>
                        <li><p>Επισκευή Συστημάτων Φυσικού Αερίου</p></li>
                        <li><p>Επισκευή και Συντρήση Καλωδιώσεων Λέβητα</p></li>
                        <li><p>Έλεγχος Ποιότητας Καυσαερίων Λέβητα</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Service1Main